/*jshint esversion: 6 */

import '../node_modules/bootstrap';
import '../node_modules/htmx.org';

import Carousel from '../node_modules/mdb-ui-kit/src/mdb/js/free/carousel'; // carousel
import MultiCarousel from '../node_modules/mdb-ui-kit/src/plugins/multi-carousel/js/multi-carousel'; // multi carousel

import accept_cookies from './src/accept_cookies';
import cart from './src/cart';
import cookie from './src/cookie';
import language_chooser from './src/language_chooser';
import quantity from './src/quantity';
import separate_shipping_address from './src/separate_shipping_address';
import tagsearch from './src/tagsearch';
