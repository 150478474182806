class TagFilter {
  constructor(all_tags) {
    this.ALL_TAGS = all_tags;

    this.TAG_SElECT = document.getElementsByClassName("tag-select");
    for (let i = 0; i < this.TAG_SElECT.length; i++) {
      this.TAG_SElECT[i].addEventListener('click',this.updateTags.bind(this), false);
    }
    this.TAG_SElECT1 = document.getElementById("tag-select1");
    if (this.TAG_SElECT1 != null) {
      this.TAG_SElECT1.addEventListener('click',this.updateTags.bind(this), false);
      this.TAG_SElECT1.addEventListener('keypress', this.enterKey.bind(this), false);
    }
    this.TAG_SElECT2 = document.getElementById("tag-select2");
    if (this.TAG_SElECT2 != null) {
      this.TAG_SElECT2.addEventListener('click',this.updateTags.bind(this), false);
      this.TAG_SElECT2.addEventListener('keypress', this.enterKey.bind(this), false);
    }
    this.TAG_SElECT3 = document.getElementById("tag-select3");
    if (this.TAG_SElECT3 != null) {
      this.TAG_SElECT3.addEventListener('click',this.updateTags.bind(this), false);
      this.TAG_SElECT3.addEventListener('keypress', this.enterKey.bind(this), false);
    }

    this.FILTER_TAG = document.getElementById("filter-tags");
    this.FILTER_TAGS_INPUT = document.getElementById("filter-tags-input");
    if (this.FILTER_TAGS_INPUT != null) {
      this.FILTER_TAGS_INPUT.focus()
      this.FILTER_TAGS_INPUT.addEventListener('keyup', this.filterTags.bind(this), false);
      this.FILTER_TAGS_INPUT.addEventListener('input',this.inputChange.bind(this), false);
    }
    this.FILTER_TAGS_INPUT3 = document.getElementById("filter-tags-input3");
    if (this.FILTER_TAGS_INPUT3 != null) {
      this.FILTER_TAGS_INPUT3.focus()
      if (this.FILTER_TAGS_INPUT3.value != "") {
        var keyword, tag;
        keyword = this.FILTER_TAGS_INPUT3.value;
        tag = this.TAG_SElECT3;
        this.filterTag(keyword.toLowerCase(), tag)
      }
      this.FILTER_TAGS_INPUT3.addEventListener('keyup', this.filterTags3.bind(this), false);
      this.FILTER_TAGS_INPUT3.addEventListener('input',this.inputChange.bind(this), false);
    }
    this.FILTER_TAGS_INPUT2 = document.getElementById("filter-tags-input2");
    if (this.FILTER_TAGS_INPUT2 != null) {
      if (this.FILTER_TAGS_INPUT2.value == "") {
        this.FILTER_TAGS_INPUT2.focus()
      } else {
        var keyword, tag;
        keyword = this.FILTER_TAGS_INPUT2.value;
        tag = this.TAG_SElECT2;
        this.filterTag(keyword.toLowerCase(), tag)
      }
      this.FILTER_TAGS_INPUT2.addEventListener('keyup', this.filterTags2.bind(this), false);
      this.FILTER_TAGS_INPUT2.addEventListener('input',this.inputChange.bind(this), false);
    }
    this.FILTER_TAGS_INPUT1 = document.getElementById("filter-tags-input1");
    if (this.FILTER_TAGS_INPUT1 != null) {
      if (this.FILTER_TAGS_INPUT1.value == "") {
        this.FILTER_TAGS_INPUT1.focus()
      } else {
        var keyword, tag;
        keyword = this.FILTER_TAGS_INPUT1.value;
        tag = this.TAG_SElECT1;
        this.filterTag(keyword.toLowerCase(), tag)
      }
      this.FILTER_TAGS_INPUT1.addEventListener('keyup', this.filterTags1.bind(this), false);
      this.FILTER_TAGS_INPUT1.addEventListener('input',this.inputChange.bind(this), false);
    }
    this.FILTER_MANUFACTURER = document.getElementsByClassName("filter-manufacturer");
    for (let i = 0; i < this.FILTER_MANUFACTURER.length; i++) {
      this.FILTER_MANUFACTURER[i].addEventListener('click',this.updateTags.bind(this), false);
    }
  }

  inputChange(event) {
    const tag = event.currentTarget.name;
    const value = event.currentTarget.value;
    if (value=="") {
      this.updateParameter(tag, value);
    }
  }

  updateTags(event) {
    const tag = event.currentTarget.name;
    const value = event.currentTarget.value;
    this.updateParameter(tag, value);
  }

  enterKey(event) {
    if (event.key === 'Enter') {
      this.updateTags(event)
    }
  }

  filterTag(keywordText, tag) {
    var i, count, searchText, searchResult;
    count = 0;
    for (i = 0; i < tag.length; i++) {
      searchText = tag.options[i].value.toLowerCase();
      searchResult = searchText.search(keywordText);
      if (searchResult > -1 || tag.options[i].selected) {
        count++;
        if (tag.options[i].classList.contains('d-none')) {
          tag.options[i].classList.remove("d-none");
        }
        if (tag.options[i].classList.contains('d-block')) {
          tag.options[i].classList.remove("d-block");
        }
      } else {
        tag.options[i].classList.add("d-none");
        if (tag.options[i].classList.contains('d-block')) {
          tag.options[i].classList.remove("d-block");
        }
      }
    }
    if (count == 0) {
      for (i = 0; i < self.all_tags.length; i++) {
        searchText = self.all_tags[i].toLowerCase();
        searchResult = searchText.search(keywordText);
        if (searchResult > -1) {
          count++;
          var option = document.createElement("option");
          option.text = self.all_tags[i];
          option.value = self.all_tags[i];
          tag.add(option, tag[0]);
        }
      }
    }
    if (count == 0) {
      for (i = 0; i < tag.length; i++) {
        if (tag.options[i].classList.contains('d-none')) {
          tag.options[i].classList.remove("d-none");
        }
      }
    }
    return count;
  }

  clickTag(keywordText, tag) {
    var i, searchText, searchResult;
    for (i = 0; i < tag.length; i++) {
      searchText = tag.options[i].value.toLowerCase();
      searchResult = searchText.search(keywordText);
      if (searchResult > -1 || tag.options[i].selected) {
        this.updateParameter(tag.name, tag.options[i].value);
      }
    }
  }

  filterTags() {
    var i, count, keyword, tag;
    keyword = this.FILTER_TAGS_INPUT.value;
    for (i = 0; i < this.TAG_SElECT.length; i++) {
      tag = this.TAG_SElECT[i];
      count = this.filterTag(keyword.toLowerCase(), tag)
      if (count > 0 && keyword.length > 2) {
        this.clickTag(keyword.toLowerCase(), tag)
      }
    }
  }

  filterTags1(event) {
    var count, keyword, tag;
    if (event.key === "ArrowDown") {
      this.TAG_SElECT1.focus()
    }
    keyword = this.FILTER_TAGS_INPUT1.value;
    tag = this.TAG_SElECT1;
    count = this.filterTag(keyword.toLowerCase(), tag)
    if (count == 1 && keyword.length > 2) {
      this.clickTag(keyword.toLowerCase(), tag)
    }
  }

  filterTags2(event) {
    var count, keyword, tag;
    if (event.key === "ArrowDown") {
      this.TAG_SElECT2.focus()
    }
    keyword = this.FILTER_TAGS_INPUT2.value;
    tag = this.TAG_SElECT2;
    count = this.filterTag(keyword.toLowerCase(), tag)
    if (count == 1 && keyword.length > 2) {
      this.clickTag(keyword.toLowerCase(), tag)
    }
  }

  filterTags3(event) {
    var count, keyword, tag;
    if (event.key === "ArrowDown") {
      this.TAG_SElECT3.focus()
    }
    keyword = this.FILTER_TAGS_INPUT3.value;
    tag = this.TAG_SElECT3;
    count = this.filterTag(keyword.toLowerCase(), tag)
    if (count == 1 && keyword.length > 2) {
      this.clickTag(keyword.toLowerCase(), tag)
    }
  }

  getQueryParameter() {
    var i, hash, hashes, vars;
    vars = [];
    hashes = window.location.href;
    if (window.location.href.indexOf('#') > 0) {
      hashes = hashes.substr(0, window.location.href.indexOf('#'))
    }
    if (window.location.href.indexOf('?') > 0) {
      hashes = hashes.slice(window.location.href.indexOf('?') + 1);
      hashes = hashes.split('&');
      if (hashes.length > 0) {
        for (i = 0; i < hashes.length; i++) {
          hash = hashes[i].split('=');
          if (vars[hash[0]]) {
            vars[hash[0]].push(hash[1]);
          } else {
            vars[hash[0]] = [hash[1],];
          }
        }
      }
    }
    return vars;
  }

  setQueryParameter(vars) {
    var i, j, url, keys, values;
    url = "?";
    keys = Object.keys(vars);
    for (i = 0; i < keys.length; i++) {
      values = vars[keys[i]];
      for (j = 0; j < values.length; j++) {
        if (values[j]) {
          url += keys[i] + "=" + values[j] + "&"
        }
      }
    }
    if (url[url.length - 1] == '&') {
      url = url.substr(0, url.length - 1);
    }
    return url
  }

  updateParameter(tag, value) {
    var tag, value, queryParameter, index
    tag = encodeURI(tag);
    value = encodeURI(value);
    queryParameter = this.getQueryParameter();
    delete queryParameter["page"];
    delete queryParameter["m"];
    if (value == 'all') {
      delete queryParameter[tag];
    } else if (queryParameter[tag]) {
      delete queryParameter[tag];
    }
    queryParameter[tag] = [value,];
    document.location = this.setQueryParameter(queryParameter);
  }
}

if (typeof(all_tags) == "undefined") {
  all_tags = [];
}
const classTagFilter = new TagFilter(all_tags);
window.classTagFilter = classTagFilter;
