import {getCookie} from "./cookie";

class AcceptCookies {
  constructor(ACCEPT_COOKIES_URL) {
    this.ACCEPTCOOKIES = false;
    this.ACCEPT_COOKIES_URL = ACCEPT_COOKIES_URL;
    this.DATA = {};

    this.ACCEPT_COOKIE_BUTTON = document.getElementById('accept-cookies-button');
    if (this.ACCEPT_COOKIE_BUTTON !== null) {
      this.ACCEPT_COOKIE_BUTTON.addEventListener('click', this.acceptCookiesFunction.bind(this));
    }

    this.REFUSE_COOKIE_BUTTON = document.getElementById('refuse-cookies-button');
    if (this.REFUSE_COOKIE_BUTTON !== null) {
       this.REFUSE_COOKIE_BUTTON.addEventListener('click', this.refuseCookiesFunction.bind(this));
    }
  }

  acceptCookiesFunction() {
      this.ACCEPTCOOKIES = true;
      this.submitFunction()
  }

  refuseCookiesFunction() {
      this.submitFunction()
  }

  submitFunction() {
    var data;
    if (this.ACCEPTCOOKIES == true) {
      this.DATA = {'accept_cookies': 'True', 'csrfmiddlewaretoken': getCookie('csrftoken')};
    } else {
      this.DATA = {'accept_cookies': 'False', 'csrfmiddlewaretoken': getCookie('csrftoken')};
    }
    var xhr = new XMLHttpRequest();
    xhr.open('POST', this.ACCEPT_COOKIES_URL, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
    xhr.onload = function() {
        // Successful request
        if (xhr.status == 200) {
          document.getElementById('accept-cookies').classList.add('d-none');
          document.getElementById('accept-cookies-placeholder').classList.add('d-none');
        }
    };
    xhr.onerror = function() {
        // Crossdomain request denied
        if (xhr.status === 0) {
        }
    };
    xhr.send(JSON.stringify(this.DATA));
  }
}

if (typeof ACCEPT_COOKIES_URL !== 'undefined') {
  const classAcceptCookies = new AcceptCookies(ACCEPT_COOKIES_URL);
  window.AcceptCookies = classAcceptCookies;
}
