class Quantity {
  constructor() {
    this.QUANTITY_PLUS = document.getElementsByClassName('quantityPlus');
    if (this.QUANTITY_PLUS != null) {
      for (let i = 0; i < this.QUANTITY_PLUS.length; i++) {
        this.QUANTITY_PLUS[i].addEventListener('click', this.quantityUp.bind("this"), false);
      }
    }

    this.QUANTITY_MINUS = document.getElementsByClassName('quantityMinus');
    if (this.QUANTITY_MINUS != null) {
      for (let i = 0; i < this.QUANTITY_MINUS.length; i++) {
        this.QUANTITY_MINUS[i].addEventListener('click', this.quantityDown.bind("this"), false);
      }
    }
  }

  quantityUp(event) {
    var quantity_div = event.currentTarget.closest('div.iconbuttonsgroup');
    var input_name = "quantity-" + event.currentTarget.name;
    var quantity_input = document.getElementById(input_name);
    var count = parseInt(quantity_input.value);
    count++;
    quantity_input.value = count.toString();
    quantity_input.trigger("change");
  }

  quantityDown(event) {
    var quantity_div = event.currentTarget.closest('div.iconbuttonsgroup');
    var input_name = "quantity-" + event.currentTarget.name;
    var quantity_input = document.getElementById(input_name);
    var count = parseInt(quantity_input.value);
    count--;
    if (count < 1) {
        count = 1;
    }
    quantity_input.value = count.toString();
    quantity_input.trigger("change");
  }

}

const classQuantity = new Quantity();
window.classQuantity = classQuantity;
