class Cart {
  constructor() {
    const REFRESH_BUTTON = document.getElementById("cart-refresh")
    if (REFRESH_BUTTON != null) {
      REFRESH_BUTTON.classList.add('d-none');
    }
    const EMPTY_BUTTON = document.getElementById("empty-cart")
    if (EMPTY_BUTTON != null) {
      EMPTY_BUTTON.classList.add('d-none');
    }
    this.QUANTITY_INPUT = document.getElementsByClassName("quantity-input");
    if (this.QUANTITY_INPUT != null) {
      for (let i = 0; i < this.QUANTITY_INPUT.length; i++) {
        this.QUANTITY_INPUT[i].addEventListener('change', this.submitFunction, false);
      }
    }

    this.ITEM_QUANTITY = document.getElementsByClassName('item-quantity');
    if (this.ITEM_QUANTITY != null) {
      for (let i = 0; i < this.ITEM_QUANTITY.length; i++) {
        this.ITEM_QUANTITY[i].addEventListener('change', this.submitFunction, false);
      }
    }

    this.DELETE_BUTTON = document.getElementsByClassName('deletebutton');
    if (this.DELETE_BUTTON != null) {
      for (let i = 0; i < this.DELETE_BUTTON.length; i++) {
        this.DELETE_BUTTON[i].addEventListener('click', this.deleteCartItem, false);
      }
    }
  }

  submitFunction() {
    document.getElementById("cartform").submit();
  }

  deleteCartItem(event) {
    const DELETE_LINK = event.currentTarget.closest('.deletebutton');
    const ITEM_ID = DELETE_LINK.attributes['data-item-id'];
    if (ITEM_ID !== undefined) {
      let input_id = 'id_cartitem_set-' + ITEM_ID.value + '-DELETE';
      let input = document.getElementById(input_id);
      input.checked = true;
    }
    document.getElementById("cartform").submit();
  }
}

const classCart = new Cart();
window.classCart = classCart;
