class SeparateShippingAddress {
  constructor() {
    this.SEPARATE_SHIPPING_ADDRESS = document.getElementById('id_billing-separate_shipping_address')
    if (this.SEPARATE_SHIPPING_ADDRESS != null) {
      this.SEPARATE_SHIPPING_ADDRESS.addEventListener('change', this.showOrHideShippingAddress);
    }
    this.showOrHideShippingAddress;
  }

  showOrHideShippingAddress() {
    var elem = document.getElementById('id_billing-separate_shipping_address');
    if (elem.checked) {
        document.getElementById('shipping-address').classList.add('show');
    } else {
        document.getElementById('shipping-address').classList.remove('show');
    }
  }
}

const classSeparateShippingAddress = new SeparateShippingAddress();
window.SeparateShippingAddress = classSeparateShippingAddress;
