class LanguageChooser {
  constructor() {
    this.LANGUAGE_CHOOSER_FORM = document.getElementById('languagechooser');
    if (this.LANGUAGE_CHOOSER_FORM != null) {
      this.LANGUAGE_CHOOSER_FORM.addEventListener('change', this.submitFunction.bind(this));
    }
  }

  submitFunction() {
    this.LANGUAGE_CHOOSER_FORM.submit();
  }
}

const classLanguageChooser = new LanguageChooser();
window.LanguageChooser = classLanguageChooser;
